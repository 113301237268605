import { useTranslate } from "app/hooks/lang";

const useMetaDescription = () => {
  const t = useTranslate();

  return (text?: string) => (
    <meta
      name="description"
      content={text || t("meta.description")}
    />
  );
}

export default useMetaDescription;