import clsx from "clsx";
import { Category } from "services-hooks/types";
import styles from "./stations-list.module.scss";
import Image from "next/image";
import MemoLogo from "../icons/Logo";
import { useRef, useEffect } from "react";
import { useIntersection } from "react-use";
import { useIsMobileResolution } from "app/utils/is-mobile-resolution";

interface StationsRowProps {
  items: Category[];
  wrapped?: boolean;
  column?: boolean;
  showStationNames?: boolean;
  handleOnClick: (radioStation: Category, index: number) => void;
  scrollSyncId?: string;
  navigationDisabled?: boolean;
  fetchNextPage?: VoidFunction;
  triggerFetchNextPage?: boolean;
  big?: boolean;
}

export const StationsRow: React.FC<StationsRowProps> = ({
  items,
  wrapped,
  showStationNames,
  handleOnClick,
  scrollSyncId,
  navigationDisabled,
  fetchNextPage = () => {},
  triggerFetchNextPage,
  column,
  big = false,
}) => {
  const loadMoreTriggerRef = useRef(null);
  const intersection = useIntersection(loadMoreTriggerRef, {
    rootMargin: "0px",
    threshold: 1,
  });

  const isMobile = useIsMobileResolution();

  useEffect(() => {
    if (triggerFetchNextPage && intersection?.isIntersecting) {
      fetchNextPage();
    }
  }, [intersection, triggerFetchNextPage]);
  return (
    <div
      className={clsx(styles.row, {
        [styles.vertical]: column,
        [styles.wrapped]: wrapped,
        [styles.over_two]: items.length > 2,
      })}
      data-navigate-row={!navigationDisabled}
      data-navigate-scrollable
      data-navigate-scrollable-parent
      data-sync-scroll-position={scrollSyncId}
    >
      {items.map((radioStation, index) => (
        <div
          key={`station-row-${index}-${radioStation.permalink}`}
          data-navigate-row={!navigationDisabled && column}
        >
          <div
            data-navigate-item={!navigationDisabled}
            tabIndex={0}
            className={clsx(styles.radio_station, {
              [styles.show_station_names]: showStationNames,
              [styles.big]: big,
            })}
            onClick={() =>
              handleOnClick(radioStation, items.indexOf(radioStation))
            }
          >
            {radioStation?.logo || radioStation?.logo ? (
              <div className={styles.cover_wrapper}>
                <Image
                  className={styles.cover}
                  src={radioStation?.logo || radioStation?.logo || ""}
                  layout="fill"
                  objectFit="cover"
                  alt=""
                  unoptimized
                />
              </div>
            ) : (
              <div className={clsx(styles.cover, styles.no_image)}>
                <MemoLogo className={styles.no_image_img} />
              </div>
            )}
            {showStationNames && (
              <div className={styles.station_title}>{radioStation.name}</div>
            )}
          </div>
        </div>
      ))}
      {triggerFetchNextPage && <div ref={loadMoreTriggerRef} />}
    </div>
  );
};
